import React from "react"
import { Affix, Button, Carousel, Col, Row, Typography } from "antd"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Shell from "../components/Shell/Shell"
import BookingHero from "../components/BookingHero/BookingHero"
import SeoHeader from "../components/SeoHeader/SeoHeader"
import WRPSearch from "../components/WRPSearch/WRPSearch"
import MediaContext from "@avldev/gatsby-theme-core-ui/src/components/MediaContext"
import SEO from "@avldev/gatsby-theme-core-ui/src/components/SEO"

const fullRow = {
  align: "middle",
  gutter: 36,
  justify: "center",
}

const bodyWidth = {
  xs: 22,
  md: 22,
  lg: 20,
  xl: 18,
}

const fullCol = {
  xs: bodyWidth.xs,
  md: bodyWidth.md,
  lg: 18,
  xl: 16,
}

const halfCol = {
  xs: bodyWidth.xs,
  md: bodyWidth.md / 2,
  lg: bodyWidth.lg / 2,
  xl: bodyWidth.xl / 2,
}

const halfColAlt = order => {
  return {
    xs: {
      order: (order % 2) + 1,
      span: 22,
    },
    md: {
      order: order,
      span: bodyWidth.md / 2,
    },
    lg: bodyWidth.lg / 2,
    xl: bodyWidth.xl / 2,
  }
}

const description =
  "Residences at Biltmore All-Suite Hotel is less than half a mile from Biltmore Estate and Mission Hospital, and conveniently located to downtown Asheville NC."

export default ({ data, location }) => {
  const [boxShadow, setBoxShadow] = React.useState(0)
  const { reduceMotion } = React.useContext(MediaContext)
  const {
    eventsImage,
    foodBottom,
    foodTop,
    gallery,
    quadBotLeft,
    quadBotRight,
    quadTopLeft,
    quadTopRight,
  } = data

  const handleAffixChange = affixed => {
    setBoxShadow(affixed ? `0 1px 6px 3px rgba(0,0,0,0.15)` : `none`)
  }

  return (
    <Shell>
      <SEO
        meta={{
          description,
          image: quadTopLeft.fluid.src,
          imageAlt: quadTopLeft.description,
        }}
        title="Hotels Near Biltmore Estate, Asheville NC - The Residences at Biltmore"
        url={location.href}
      />
      <BookingHero height={`70vh`} />
      {/* Intro */}
      <Row {...fullRow}>
        <Col {...fullCol} style={{ marginTop: `32px`, textAlign: `center` }}>
          <Typography.Title level={1} style={{ fontSize: 32, marginBottom: 8 }}>
            Welcome to The Residences at Biltmore
          </Typography.Title>
          <Typography.Text
            style={{
              color: `rgba(0,0,0,0.65)`,
              fontFamily: `legitima`,
              fontSize: 18,
            }}
          >
            An all-suite hotel just 2 miles from Downtown Asheville
            <br />
            (Not affiliated with Biltmore Estate)
          </Typography.Text>
        </Col>
      </Row>
      {/* The Rooms */}
      <Row
        {...fullRow}
        style={{
          margin: `auto`,
          maxWidth: 1360,
          paddingBottom: 64,
          paddingTop: 48,
        }}
      >
        <Col {...halfCol}>
          <Carousel
            autoplay
            effect={reduceMotion ? `fade` : `scrollx`}
            className="shadow-sm"
            draggable
          >
            {gallery.images.map((img, i) => (
              <Img alt={img.description} fluid={img.fluid} key={i} />
            ))}
          </Carousel>
        </Col>
        <Col {...halfCol}>
          <SeoHeader
            seo={{ content: `Hotel`, level: 2 }}
            human={{ content: `The Suites`, level: 4 }}
          />
          <Typography.Paragraph style={{ margin: `24px auto` }}>
            Stay like a local at The Residences at Biltmore, where every room
            boasts a full-sized kitchen, gas fireplace, private patio or
            balcony, and at least one large bathroom with a glass-enclosed
            shower. With floor plans ranging in size from studio to
            three-bedroom, and on-site amenities including a pool & hot tub,
            fitness center, firepit, and a restaurant & bar, visiting Asheville
            has never been so comfortable — or convenient.
          </Typography.Paragraph>
          <Button className="norm-btn" size="large" type="primary">
            <Link to="/lodging-types/">See the Suites</Link>
          </Button>
        </Col>
      </Row>
      {/* Affixed CTA */}
      <Row {...fullRow}>
        <Affix onChange={handleAffixChange}>
          <Row
            {...fullRow}
            style={{
              background: `#E6E1D3`,
              boxShadow,
              padding: 8,
              transition: `0.2s`,
              width: `100vw`,
            }}
          >
            <Col {...fullCol} style={{ maxWidth: 900, textAlign: `center` }}>
              <Typography.Title level={4}>Check Availability</Typography.Title>
              <WRPSearch hotelID={process.env.GATSBY_WRP_HOTEL_ID} />
            </Col>
          </Row>
        </Affix>
      </Row>
      {/* An Incredible Place */}
      <div style={{ background: `#F7F7F7` }}>
        <Row
          {...fullRow}
          style={{
            margin: `auto`,
            maxWidth: 1360,
            paddingBottom: 96,
            paddingTop: 48,
            textAlign: `center`,
          }}
        >
          <Col {...fullCol}>
            <Typography.Title
              level={2}
              style={{ fontSize: 48, lineHeight: `44px` }}
            >
              An incredible place to stay
            </Typography.Title>
            <Typography.Paragraph
              style={{ marginBottom: 48, textAlign: `center` }}
            >
              The Residences at Biltmore sets the precedent for luxury
              accommodations in the Asheville area. This condo hotel is just
              outside the gates of Biltmore in South Asheville. Convenient to
              downtown Asheville, Biltmore Village, and all of the most
              sought-out tourist destinations, restaurants, and shopping, The
              Residences at Biltmore is the premier Asheville condo-hotel.
            </Typography.Paragraph>
            <Row align="middle" gutter={12}>
              <Col xs={24} md={12} style={{ height: 275, marginBottom: 12 }}>
                <Img
                  alt={quadTopLeft.description}
                  className="shadow-sm"
                  fluid={quadTopLeft.fluid}
                  style={{ height: `100%` }}
                />
              </Col>
              <Col xs={24} md={12} style={{ height: 275, marginBottom: 12 }}>
                <Img
                  alt={quadTopRight.description}
                  className="shadow-sm"
                  fluid={quadTopRight.fluid}
                  style={{ height: `100%` }}
                />
              </Col>
            </Row>
            <Row align="middle" gutter={12}>
              <Col xs={24} md={12} style={{ height: 275, marginBottom: 12 }}>
                <Img
                  alt={quadBotLeft.description}
                  className="shadow-sm"
                  fluid={quadBotLeft.fluid}
                  style={{ height: `100%` }}
                />
              </Col>
              <Col xs={24} md={12} style={{ height: 275, marginBottom: 12 }}>
                <Img
                  alt={quadBotRight.description}
                  className="shadow-sm"
                  fluid={quadBotRight.fluid}
                  style={{ height: `100%` }}
                />
              </Col>
            </Row>
            <Button
              className="norm-btn"
              size="large"
              type="primary"
              style={{ margin: `32px auto` }}
            >
              <Link to="/amenities/">See Amenities</Link>
            </Button>
          </Col>
        </Row>
      </div>
      {/* The Food */}
      <Row
        {...fullRow}
        style={{
          margin: `auto`,
          maxWidth: 1360,
          paddingBottom: 72,
          paddingTop: 72,
        }}
      >
        <Col {...halfColAlt(2)} style={{ marginBottom: 32, marginTop: -104 }}>
          <Img
            alt={foodTop.description}
            className="shadow-sm"
            fluid={foodTop.fluid}
          />
          <div style={{ height: 8 }} />
          <Img
            alt={foodBottom.description}
            className="shadow-sm"
            fluid={foodBottom.fluid}
          />
        </Col>
        <Col {...halfColAlt(1)}>
          <SeoHeader
            seo={{ content: `Dining`, level: 2 }}
            human={{ content: `The Food`, level: 4 }}
          />
          <Typography.Paragraph>
            Start — or finish — your day on the town at Azalea Bar & Kitchen,
            our onsite spot for craft cocktails, local brews, and crave-able,
            Southern-inspired table-shares.
          </Typography.Paragraph>
          <Button
            className="norm-btn"
            size="large"
            type="primary"
            style={{ marginTop: 12 }}
          >
            <Link to="/dining/">Chow Down</Link>
          </Button>
        </Col>
      </Row>
      {/* Groups */}
      <div style={{ background: `#F7F7F7` }}>
        <Row
          {...fullRow}
          style={{
            marginBottom: -32,
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: 1360,
            paddingBottom: 72,
            paddingTop: 72,
          }}
        >
          <Col {...halfCol}>
            <Img
              alt={eventsImage.description}
              className="shadow-sm"
              fluid={eventsImage.fluid}
            />
          </Col>
          <Col {...halfCol}>
            <SeoHeader
              seo={{ content: `Groups`, level: 2 }}
              human={{ content: `The Fun`, level: 4 }}
            />
            <Typography.Paragraph>
              We don’t take event planning - or fun - lightly. Every reception,
              reunion, meeting, or get-together you plan at The Residences at
              Biltmore is guaranteed to be a memorable one, whether it’s in our
              open-air venue or one of our indoor spaces.
            </Typography.Paragraph>
            <Button
              className="norm-btn"
              size="large"
              type="primary"
              style={{ marginTop: 12 }}
            >
              <Link to="/groups/">Book Your Group</Link>
            </Button>
          </Col>
        </Row>
      </div>
    </Shell>
  )
}

export const query = graphql`
  query {
    eventsImage: contentfulAsset(title: { eq: "ABK - Lawn - 1" }) {
      description
      fluid(maxWidth: 450) {
        ...fluid_withWebp_tracedSVG
      }
    }
    foodBottom: contentfulAsset(title: { eq: "ABK - Hot Chicken Sandwhich" }) {
      description
      fluid(maxWidth: 450) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    foodTop: contentfulAsset(title: { eq: "ABK - Patio - 1" }) {
      description
      fluid(maxWidth: 450) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    gallery: contentfulImageGallery(
      name: { eq: "Home Page - Room Interiors" }
      business: { elemMatch: { name: { eq: "Residences at Biltmore" } } }
    ) {
      images {
        description
        fluid(maxWidth: 600, toFormat: WEBP) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    quadBotLeft: contentfulAsset(
      title: { eq: "freestocks-a1Fmxesw31g-unsplash(1)" }
    ) {
      description
      fluid(maxWidth: 400, resizingBehavior: FILL) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    quadBotRight: contentfulAsset(title: { eq: "blue-ridge-parkway-sunset" }) {
      description
      fluid(maxWidth: 400, resizingBehavior: FILL) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    quadTopLeft: contentfulAsset(title: { eq: "rab-studio-bed-sofa" }) {
      description
      fluid(maxWidth: 400, resizingBehavior: FILL) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    quadTopRight: contentfulAsset(title: { eq: "rab-balcony" }) {
      description
      fluid(maxWidth: 400, resizingBehavior: FILL) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`
